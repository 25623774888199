<template>
  <div>
    <p v-show="errorTableData" class="text-center">
      <v-icon>mdi-alert-circle</v-icon>
      Error obteniendo los datos
    </p>

    <v-card v-if="!errorTableData">
      <v-card-title>
        <NuevoUsuario @edit="getTableData()"></NuevoUsuario>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :search="search"
        :loadingTableData="loadingTableData"
        loadingTableData-text="Cargando..."
      >
        <template v-slot:item.fechaRegistro="{ item }">
          {{ new Date(item.fechaRegistro).toLocaleDateString() }}
        </template>

        <template v-slot:item.ultimaModificacion="{ item }">
          {{ new Date(item.ultimaModificacion).toLocaleString() }}
        </template>

        <template v-slot:item.nombre="{ item }">
          {{ item.nombre }} {{ item.primerApellido }} {{ item.segundoApellido }}
        </template>

        <template v-slot:item.acciones="{ item }">
          <EditarUsuario :usuario="item" @edit="getTableData()"></EditarUsuario>

          <v-icon color="red" @click="eliminarRow(item._id)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import NuevoUsuario from '../components/Usuarios/NuevoUsuario';
import EditarUsuario from '../components/Usuarios/EditarUsuario';

export default {
  data() {
    return {
      tableData: [],
      errorTableData: false,
      loadingTableData: true,
      search: '',
      tableHeaders: [
        { text: 'Usuario', value: 'usuario' },
        { text: 'Correo', value: 'correo' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Fecha de registro', value: 'fechaRegistro' },
        { text: 'Última modificación', value: 'ultimaModificacion' },
        {
          text: 'Acciones',
          value: 'acciones',
          sortable: false,
          align: 'center',
        },
      ],
    };
  },
  methods: {
    async getTableData() {
      try {
        this.loadingTableData = true;

        const res = await axios.get(process.env.VUE_APP_API_URL + '/usuarios');

        this.tableData = res.data;

        this.loadingTableData = false;
      } catch (error) {
        this.loadingTableData = false;

        this.errorTableData = true;
      }
    },
    async eliminarRow(id) {
      try {
        this.loadingTableData = true;
        await axios.post(process.env.VUE_APP_API_URL + '/usuarios/eliminar', {
          id,
        });
        this.getTableData();
      } catch (error) {
        alert(error.response.data);
      }
    },
  },
  components: {
    NuevoUsuario,
    EditarUsuario,
  },
  mounted() {
    this.getTableData();
    this.$emit('usePanel', true);
  },
};
</script>
