<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-4" dark color="blue" v-bind="attrs" v-on="on">
        <v-icon dark>
          mdi-plus
        </v-icon>
        Nuevo
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Nuevo usuario
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Usuario"
          v-model="nuevoUsuario.usuario"
        ></v-text-field>

        <v-text-field
          v-model="nuevoUsuario.passwd"
          :append-icon="showPasswd ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPasswd ? 'text' : 'password'"
          name="input-10-1"
          label="Contraseña"
          hint="De 6 a 30 caracteres"
          counter
          @click:append="showPasswd = !showPasswd"
        ></v-text-field>

        <v-text-field
          label="Correo"
          v-model="nuevoUsuario.correo"
        ></v-text-field>

        <v-text-field
          label="Nombre"
          v-model="nuevoUsuario.nombre"
        ></v-text-field>

        <v-text-field
          label="Primer apellido"
          v-model="nuevoUsuario.primerApellido"
        ></v-text-field>

        <v-text-field
          label="Segundo apellido"
          v-model="nuevoUsuario.segundoApellido"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue" text @click="toggleDialog = false">
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="crearUsuario()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NuevoUsuario',
  data() {
    return {
      loading: false,
      toggleDialog: false,
      showPasswd: false,
      nuevoUsuario: {
        usuario: '',
        passwd: '',
        correo: '',
        nombre: '',
        primerApellido: '',
        segundoApellido: '',
      },
    };
  },
  methods: {
    async crearUsuario() {
      try {
        this.loading = true;

        await axios.post(process.env.VUE_APP_API_URL + '/usuarios/nuevo', this.nuevoUsuario);

        this.loading = false;

        this.toggleDialog = false;

        this.$emit('edit');
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
  },
};
</script>
