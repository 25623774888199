<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-icon class="mr-2" color="orange" v-bind="attrs" v-on="on">
        mdi-pencil
      </v-icon>
    </template>

    <v-card :loading="loading">
      <v-card-title> Editar usuario {{ usuario.usuario }} </v-card-title>
      <v-card-text>
        <v-text-field label="Correo" v-model="usuario.correo"></v-text-field>

        <v-text-field
          v-model="usuario.passwd"
          :append-icon="showPasswd ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPasswd ? 'text' : 'password'"
          name="input-10-1"
          label="Contraseña"
          hint="Déjala vacia para no cambiarla. De 6 a 30 caracteres"
          counter
          @click:append="showPasswd = !showPasswd"
        ></v-text-field>

        <v-text-field label="Nombre" v-model="usuario.nombre"></v-text-field>

        <v-text-field
          label="Primer apellido"
          v-model="usuario.primerApellido"
        ></v-text-field>

        <v-text-field
          label="Segundo apellido"
          v-model="usuario.segundoApellido"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue"
          text
          @click="
            toggleDialog = false;
            $emit('edit');
          "
        >
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="editarUsuario()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EditarUsuario',
  props: ['usuario'],
  data() {
    return {
      loading: false,
      toggleDialog: false,
    };
  },
  methods: {
    async editarUsuario() {
      try {
        this.loading = true;

        await axios.post(process.env.VUE_APP_API_URL + '/usuarios/editar', {
          id: this.usuario._id,
          passwd: this.usuario.passwd,
          correo: this.usuario.correo,
          nombre: this.usuario.nombre,
          primerApellido: this.usuario.primerApellido,
          segundoApellido: this.usuario.segundoApellido,
        });

        this.loading = false;

        this.toggleDialog = false;
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
  },
};
</script>
